import React from 'react'
import styles from './Loader.module.css'
import { useProgress } from '@react-three/drei';

function Loader() {

    const { progress } = useProgress();

    return (
        <div className={styles.window}>
            <div className={styles.logo}>
                <p className={styles.top}>Microsoft</p>
                <p className={styles.mid}>Windows<span>XP</span></p>
                <p className={styles.bottom}>Professional</p>
            </div>
            <div className={styles.container}>
                <div className={styles.box}></div>
                <div className={styles.box}></div>
                <div className={styles.box}></div>
            </div>

            <span className={styles.progress}>{progress}</span>
        </div>
    )

}

export default Loader