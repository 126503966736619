import React, { Suspense, useEffect, useState } from "react";
import Home from "./pages/Home/Home";
import Loader from "./components/Loader/Loader";
import { Route, Routes, useNavigate } from "react-router-dom";
import Auth from "./pages/Auth/Auth";
import PhoneDisplay from "./components/PhoneDisplay/PhoneDisplay";
import { check } from "./http/userApi";
import { setUser } from "./reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import AdminPanel from "./pages/AdminPanel/AdminPanel";

function App() {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      const data = await check();
      dispatch(setUser({ ...user, data }));
    } catch (error) {
      navigate('/authorization');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (isMobile) {
    return <PhoneDisplay />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {user.role === 'admin' && <Route path="/admin" element={<AdminPanel />} />}
        <Route path="/" element={<Home />} />
        <Route path="/authorization" element={<Auth />} />
      </Routes>
    </Suspense>
  );
}

export default App;
