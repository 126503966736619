import React, { useEffect } from 'react'
import styles from './Confirm.module.css'

function Confirm({ setConfirm, isPlaying, setIsPlaying }) {

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        }
    })

    const handleClick = () => {
        setIsPlaying(true);
        setConfirm(false);
    }

    return (
        <div className={styles.overlay}>

            <div className={styles.container}>
                <p className={styles.text}>Если в вашем ПК нет видеокарты, это может привести к сильным лагам!</p>

                <div className={styles.buttons}>
                    <button className={styles.back} onClick={() => setConfirm(false)}>
                        Назад
                    </button>

                    <button className={styles.confirm} onClick={() => handleClick()} >
                        Включить
                    </button>
                </div>
            </div>

        </div>
    )
}

export default Confirm