import React, { useRef } from 'react'
import styles from './Home.module.css'
import Navbar from '../../components/Navbar/Navbar'
import BioSection from '../../components/BioSection/BioSection';
import PasswordSection from '../../components/PasswordSection/PasswordSection';
import TitlePage from '../TitlePage/TitlePage';
import PhotoSection from '../../components/PhotoSection/PhotoSection';
import VisionAirPro from '../../components/VisionAirPro/VisionAirPro';
import VideoSection from '../../components/VideoSection/VideoSection';

function Home() {

  const bioSectionRef = useRef(null);
  const photoSectionRef = useRef(null);
  const visionAirProRef = useRef(null);
  const videoSectionRef = useRef(null);
  const passwordSectionRef = useRef(null);

  const sectionRefs = {
    bioSectionRef,
    photoSectionRef,
    visionAirProRef,
    videoSectionRef,
    passwordSectionRef,
  };

  return (
    <>
      <Navbar sectionRefs={sectionRefs} />
      <main className={styles.main}>

        <TitlePage />

        <section ref={bioSectionRef}><BioSection /></section>
        <section ref={photoSectionRef}><PhotoSection /></section>
        <section ref={visionAirProRef}><VisionAirPro /></section>
        <section ref={videoSectionRef}><VideoSection /></section>
        <section ref={passwordSectionRef}><PasswordSection /></section>

      </main>
    </>
  )
}

export default Home