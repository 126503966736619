import React, { useState } from 'react';
import styles from './Menu.module.css';
import { IoArrowForward } from 'react-icons/io5';
import { GoDotFill } from "react-icons/go";
import { motion } from 'framer-motion';

function Menu({ sectionRefs, setMenuOpen }) {
    const [selectedItem, setSelectedItem] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);

    const scrollToSection = (ref) => {
        setMenuOpen(false);
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const containerVariants = {
        hidden: { scale: 0.8, rotate: -10, opacity: 0 },
        visible: {
            scale: 1,
            rotate: 0,
            opacity: 1,
            transition: { duration: 0.3, delayChildren: 0.2, staggerChildren: 0.1 }
        },
        exit: { scale: 0.8, rotate: -10, opacity: 0, transition: { duration: 0.5 } },
    };

    const menuItems = ["Манифест", "У всех на виду", "Шифрование", "Монтаж", "Код доступа"];
    const sectionRefsArray = [
        sectionRefs.bioSectionRef,
        sectionRefs.photoSectionRef,
        sectionRefs.visionAirProRef,
        sectionRefs.videoSectionRef,
        sectionRefs.passwordSectionRef
    ];

    return (
        <motion.div
            className={styles.container}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <ul className={styles.list}>
                {menuItems.map((item, index) => (
                    <li
                        key={index}
                        className={styles.list__item}
                        onClick={() => {
                            setSelectedItem(index);
                            scrollToSection(sectionRefsArray[index]);
                        }}
                        onMouseEnter={() => setHoveredItem(index)}
                        onMouseLeave={() => setHoveredItem(null)}
                    >
                        <p className={styles.text}>{item}</p>
                        {selectedItem === index ? (
                            <GoDotFill className={styles.dot} />
                        ) : hoveredItem === index ? (
                            <IoArrowForward className={`${styles.arrow} ${styles.animateArrow}`} />
                        ) : null}
                    </li>
                ))}
            </ul>
        </motion.div>
    );
}

export default Menu