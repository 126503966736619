import React, { useMemo, useState } from 'react';
import img from '../../assets/img/pizda.png';
import styles from './PasswordSection.module.css';
import StarWarsEffect from '../Confetti/StarWarsEffect';
import { checkAnswer } from '../../http/userApi';
import soundComplete from '../SoundComplete/soundComplete';

function PasswordSection() {
    const [valid, setValid] = useState(false);
    const [password, setPassword] = useState("");

    const checkPassword = async () => {
        const data = await checkAnswer(password, 5);
        if (data.status) {
            soundComplete();
            setValid(true);
        }
    }

    return (
        <section className={styles.password__section}>
            <div className={styles.container}>
                <div className={styles.input__container}>
                    <label htmlFor="passwordInput" className={styles.label}>
                        Код доступа XP
                    </label>
                    <div className={styles.input__wrapper}>
                        <input type="password" id="passwordInput" className={styles.input} value={password} onChange={(e) => setPassword(e.target.value)} />
                        <button className={styles.button} onClick={() => checkPassword()}>Проверить</button>
                    </div>
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.input__container}>
                    <textarea readOnly value={'Разгадай все тайны, найди и соедини воедино все фрагменты времени, чтобы обрести ключ к заветному секрету. А так же получить зачёт.'} className={styles.textarea} name="" id="" cols="30" rows="10" />
                </div>
            </div>

            <button className={styles.second__button} onClick={() => checkPassword()}>Проверить</button>

            <img className={styles.img} src={img} alt="" />

            {valid && <StarWarsEffect />}
        </section>
    );
}

export default PasswordSection;
