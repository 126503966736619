import React, { useState } from 'react';
import styles from './VideoSection.module.css';
import { motion } from 'framer-motion';
import code from '../../assets/img/code.svg';
import Lock from '../Lock/Lock';
import VideoModal from '../VideoModal/VideoModal';
import { setRound } from '../../reducers/userReducer';
import { checkAnswer } from '../../http/userApi';
import { useDispatch, useSelector } from 'react-redux';
import soundComplete from '../SoundComplete/soundComplete';
import { saveAs } from 'file-saver';
import lastKey from '../../assets/file/lastKey.txt';


function VideoSection() {

    const [openModal, setOpenModal] = useState(false);

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const textAnimation = {
        hidden: {
            y: 50,
            opacity: 0
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition: { delay: custom * 0.2 }
        })
    }

    const [codeValue, setCodeValue] = useState('');

    const checkCode = async () => {
        if (!user.rounds[3]) {
            const data = await checkAnswer(codeValue, 4);
            dispatch(setRound({ rounds: data.rounds }));

            if (data.status) {
                soundComplete();
                saveAs(lastKey, "lastKey.txt");
            }

        }
    };

    return (
        <motion.div className={styles.bio__section} whileInView={'visible'} initial="hidden" viewport={{ amount: 0.2, once: true }} >

            <img className={styles.img__1} src={code} alt="" />
            <img className={styles.img__2} src={code} alt="" />

            <div className={styles.info__wrapper}>
                <motion.div className={styles.info} custom={1} variants={textAnimation}>
                    <motion.h2 className={styles.text}>Монтаж</motion.h2>
                </motion.div>

                <div className={styles.lock__wrapper}>
                    <div className={styles.text__area__wrapper}>
                        <h3 className={styles.h3} >Поле для ввода ваших идей</h3>
                        <textarea value={user.rounds[3] ? 'Успешно завершено!' : codeValue} onChange={e => setCodeValue(e.target.value)}
                            className={`${styles.text__area} ${user.rounds[3] ? styles.text__area__unlocked : ''}`}
                            type='code' placeholder='Введите ваш ответ здесь...' readOnly={user.rounds[3]} />
                        <button className={!user.rounds[3] ? styles.button : styles.button__unlocked} onClick={() => checkCode()}>Отправить на проверку</button>
                    </div>

                    <div onClick={() => !user.rounds[3] && setOpenModal(true)} className={styles.lock}>
                        <Lock isUnlocked={user.rounds[3]} />
                    </div>
                </div>
            </div>

            {openModal && (
                <VideoModal openModal={openModal} setOpenModal={setOpenModal} />
            )}

        </motion.div>
    );
}

export default VideoSection;
