const defaultState = {
    rounds: [],
    role: 'user',
    aggrement: false
}

export default function userReducer(state = defaultState, action) {

    switch (action.type) {
        case 'SET_USER':

            const { data } = action.payload;

            return {
                ...state,
                aggrement: data.aggrement,
                rounds: data.rounds,
                role: data.role
            }

        case 'SET_ROUND':

            const { rounds } = action.payload;

            return {
                ...state,
                rounds
            }
        case 'SET_AGREEMENT':

            const { aggrement } = action.payload;

            return {
                ...state,
                aggrement
            }

        default:
            return state
    }
}

export const setUser = info => ({ type: 'SET_USER', payload: info });
export const setRound = info => ({ type: 'SET_ROUND', payload: info });
export const setAgreement = info => ({ type: 'SET_AGREEMENT', payload: info })