import React, { useEffect, useState } from 'react';
import styles from './StarWarsEffect.module.css';

function StarWarsEffect() {
    const [backgroundActive, setBackgroundActive] = useState(false);
    const [firstWordActive, setFirstWordActive] = useState(false);
    const firstText = 'Завершено!';

    useEffect(() => {
        const backgroundTimer = setTimeout(() => {
            setBackgroundActive(true);
        }, 100);

        const firstTimer = setTimeout(() => {
            setFirstWordActive(true);
        }, 2000);

        const totalAnimationTime = 2000 + (firstText.length * 200) + 200;

        const redirectTimer = setTimeout(() => {
            window.location.href = "https://win-xp.brainwork-challenges.ru/";
        }, totalAnimationTime);

        return () => {
            clearTimeout(backgroundTimer);
            clearTimeout(firstTimer);
            clearTimeout(redirectTimer);
        };
    }, [firstText.length]);

    return (
        <section className={`${styles.container} ${backgroundActive ? styles.active : ''}`}>
            {firstText.split('').map((char, index) => (
                <span
                    key={`first-${index}`}
                    className={`${firstWordActive ? styles.text__effect : styles.text__prehide}`}
                    style={{ animationDelay: `${index * 0.2}s` }}
                >
                    {char}
                </span>
            ))}
        </section>
    );
}

export default StarWarsEffect;
