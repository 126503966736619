import React, { useEffect, useMemo, useState } from 'react';
import styles from './AdminPanel.module.css';
import BackgroundEffect from '../../components/BackgroundEffect/BackgroundEffect';
import { banUserById, getAllUsers } from '../../http/userApi';
import { IoMdArrowBack } from "react-icons/io";
import Loader from '../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';

function AdminPanel() {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();

    const banUser = async (id) => {
        setUsers(users.filter(user => user._id !== id));
        await banUserById(id);
    };

    useEffect(() => {
        getUsers();
    }, [searchTerm])

    const getUsers = async () => {
        const data = await getAllUsers(searchTerm);
        setUsers(data);
    }

    const getRound = (user) => {
        const roundKeys = ['round1', 'round2', 'round3', 'round4', 'round5'];
        const count = roundKeys.reduce((acc, key) => user[key] ? acc + 1 : acc, 0);
        return count;
    };

    return (
        <section className={styles.containerWrapper}>

            <IoMdArrowBack className={styles.backButton} onClick={() => navigate('/')} />

            <div className={styles.container}>
                <h2 className={styles.h2}>Все кто смог что то сделать</h2>

                <div className={styles.inputWrapper} >
                    <input
                        type="text"
                        placeholder="Поиск по имени..."
                        className={styles.inputSearch}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className={styles.buttonSearch} >Поиск</button>
                </div>

                <ul className={styles.usersList}>
                    {users.map(user => (
                        <li key={user._id} className={styles.user}>
                            <div className={styles.userWrapper}>
                                <p className={styles.userName}>{user.fio} - {user.group}</p>
                                <p>Этап: {getRound(user)}</p>
                            </div>
                            <button
                                onClick={() => banUser(user._id)}
                                className={styles.banButton}
                                disabled={user.isBanned}
                            >
                                Бан
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

        </section>
    );
}

export default AdminPanel;
