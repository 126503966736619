import React, { useState, useMemo } from 'react';
import styles from './Auth.module.css';
import { login, register } from '../../http/userApi';
import { useNavigate } from 'react-router-dom';
import BackgroundEffect from '../../components/BackgroundEffect/BackgroundEffect';
import Loader from '../../components/Loader/Loader';
import { setUser } from '../../reducers/userReducer';
import { useDispatch } from 'react-redux';
import ModalInfo from '../../components/ModalInfo/ModalInfo';

function Auth() {
    const [isLogin, setIsLogin] = useState(true);
    const [fio, setFio] = useState('');
    const [group, setGroup] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);

    const toggleForm = () => setIsLogin(!isLogin);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isLogin) {
            const data = await login(username, password);
            localStorage.setItem('token', data.token);
            dispatch(setUser({ data }));
            data && navigate('/');
        } else {
            const data = await register(fio, username, password, group);
            localStorage.setItem('token', data.token);
            dispatch(setUser({ data }));
            if (data) {
                setShow(true);
            }
        }
    };

    const onLoading = () => {
        setTimeout(() => setLoading(false), 1000);
    }

    const memoizedBackgroundEffect = useMemo(() => <BackgroundEffect onLoading={onLoading} />, []);

    return (
        <section className={styles.container}>
            {loading ? <Loader /> : (
                <form onSubmit={handleSubmit} className={styles.form}>
                    <h1 className={styles.title}>{isLogin ? 'Вход' : 'Регистрация'}</h1>
                    <div className={styles.formGroup}>
                        {!isLogin && (
                            <div className={styles.inputWrapper}>
                                <label htmlFor="username" className={styles.label}>ФИО</label>
                                <input
                                    type="text"
                                    id="fio"
                                    value={fio}
                                    onChange={(e) => setFio(e.target.value)}
                                    className={styles.input}
                                    placeholder='ФИО'
                                />
                            </div>
                        )

                        }
                        <div className={styles.inputSection}>
                            <div className={styles.inputWrapper}>
                                <label htmlFor="username" className={styles.label}>Логин</label>
                                <input
                                    type="text"
                                    id="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className={styles.input}
                                    placeholder='Логин'
                                />
                            </div>
                            {!isLogin && (
                                <div className={styles.inputWrapper}>
                                    <label htmlFor="username" className={styles.label}>Группа</label>
                                    <input
                                        type="text"
                                        id="group"
                                        value={group}
                                        onChange={(e) => setGroup(e.target.value)}
                                        className={styles.input}
                                        placeholder='ИБ - 32'
                                    />
                                </div>
                            )}
                        </div>
                        <div className={styles.inputWrapper}>
                            <label htmlFor="password" className={styles.label}>Пароль</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={styles.input}
                                placeholder='Пароль'
                            />
                        </div>
                    </div>
                    <button type="submit" className={styles.button}>
                        {isLogin ? 'Войти' : 'Зарегистрироваться'}
                    </button>
                    <button type="button" onClick={toggleForm} className={styles.toggleBtn}>
                        {isLogin ? 'Еще нет аккаунта? Зарегистрироваться' : 'Уже есть аккаунт? Войти'}
                    </button>
                </form>)
            }

            {memoizedBackgroundEffect}

            {loading && <Loader />}
            {show && <ModalInfo show={show} setShow={setShow} />}
        </section>
    );
}

export default Auth;
