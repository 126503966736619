import React, { useEffect } from 'react'
import styles from './ModalInfo.module.css'
import { aggrement } from '../../http/userApi';
import { setAgreement } from '../../reducers/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function ModalInfo({ show, setShow }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const handleClose = async () => {
        if (show) {
            setShow(false);
            navigate('/');
        }
        dispatch(setAgreement({ ...user, aggrement: true }));
        await aggrement(true);
    }

    return (
        <section className={styles.container}>
            <div className={styles.modal}>
                <h2 className={styles.h2}>Что к чему ?</h2>
                <div className={styles.text__wrapper}>
                    <p className={styles.text} >Тебе нужно будет пройти 4 задания и получить ключ к дополнительному контенту.</p>
                    <p className={styles.text} >Чтобы начать некоторые задания нужно на что-то нажать :)</p>
                    <p className={styles.text} >Тестировалось в: Mozilla Firefox, Google Chrome</p>
                </div>

                <button className={styles.button} onClick={() => handleClose()}>Понял</button>
            </div>

        </section>
    )
}

export default ModalInfo