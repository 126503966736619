import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import App from './App'
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './reducers';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <SnackbarProvider maxSnack={3} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
                <App />
            </SnackbarProvider >
        </BrowserRouter>
    </Provider>
);