import React, { useState } from 'react';
import { IoArrowForward } from 'react-icons/io5';
import styles from './Navbar.module.css';
import { RiAdminFill } from "react-icons/ri";
import Menu from '../Menu/Menu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Navbar({ sectionRefs }) {

    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <h1 className={styles.logo}>Try Hack Me</h1>
                <div className={styles.btn__wrapper}>
                    {user.role !== 'admin' && <RiAdminFill className={styles.admin__btn} onClick={() => navigate('/admin')} />}
                    <div className={styles.menu__btn} onClick={() => setMenuOpen(!menuOpen)}>
                        <IoArrowForward className={styles.arrow} />
                        <p className={styles.text}>MENU</p>
                    </div>
                </div>
            </div>

            {menuOpen && <Menu setMenuOpen={setMenuOpen} sectionRefs={sectionRefs} />}
        </header>
    );
}

export default Navbar;
