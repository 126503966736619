import { enqueueSnackbar } from "notistack";
import { $host, $authHost } from "./index";

export const register = async (fio, login, password, group) => {
    try {
        const { data } = await $host.post(`api/register`, {
            fio,
            login,
            password,
            group
        });
        return data;
    } catch (error) {
        enqueueSnackbar('Что-то пошло не так. Попробуйте еще раз!', { variant: 'error' });
    }
}

export const login = async (login, password) => {
    try {
        const { data } = await $host.post(`api/login`, {
            login,
            password
        });
        return data;
    } catch (error) {
        if (error.response.status === 401) {
            enqueueSnackbar('Неверный логин или пароль', { variant: 'error' });
        } else {
            enqueueSnackbar('Что-то пошло не так. Попробуйте еще раз!', { variant: 'error' });
        }
    }
}

export const check = async () => {
    try {
        const { data } = await $authHost.get(`api/auth`);
        return data;
    } catch (error) {
        console.error("Failed to fetch check:", error);
        localStorage.removeItem('token');
    }
}

export const aggrement = async (aggrement) => {
    try {
        const { data } = await $authHost.get(`api/ponyal`, {
            aggrement: aggrement
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch aggrement:", error);
        localStorage.removeItem('token');
    }
}

export const checkAnswer = async (answer, round) => {
    try {
        const { data } = await $authHost.post(`api/check_answer`, {
            answer: answer,
            round: round
        });

        data.status ? enqueueSnackbar('Успешно пройдено!', { variant: 'success' }) : enqueueSnackbar('Неверный ответ!', { variant: 'error' });

        return data;
    } catch (error) {
        console.error("Failed to fetch checkAnswer:", error);
        localStorage.removeItem('token');
    }
}

export const getAllUsers = async (fio) => {
    try {
        const { data } = await $authHost.get(`api/all_user`, {
            params: {
                fio
            }
        })
        return data;
    } catch (error) {
        console.error("Failed to fetch getAllUsers:", error);
    }
}

export const banUserById = async (id) => {
    try {
        const { data } = await $authHost.post(`api/ban_user`, {
            id: id
        })
        return data;
    } catch (error) {
        console.error("Failed to fetch banUser:", error);
    }
}