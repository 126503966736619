import React, { useEffect } from 'react'
import { IoMdClose } from "react-icons/io";
import styles from './VideoModal.module.css'
import video from '../../assets/video/5a 47 78 6a 58 32 74 6c 65 54 72 51 72 39 43 33 30 59 78 66 30 4c 4c 51 76 74 47 43 58 39 43 2b 30 4c 33 51 73 46 2f 52 67 4e 47 4c 30 4c 48 51 73 46 2f 51 76 4e 43 2b 30 4c 58 51 75 56 2f 51 76 4e 43 31 30 59 66 52 67.mp4'

function VideoModal({ openModal, setOpenModal }) {

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        }
    })

    const closeModal = () => {
        setOpenModal(false)
    }

    return (
        <div className={styles.overlay}>
            <IoMdClose className={styles.closeButton} onClick={closeModal} />
            <div className={styles.modalContent}>
                <video controls className={styles.video}>
                    <source src={video} type="video/mp4" />
                    Ваш браузер не поддерживает видео.
                </video>
            </div>
        </div>
    );

}

export default VideoModal