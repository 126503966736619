import React, { useEffect, useState } from 'react';
import styles from './TitlePage.module.css';
import { AiOutlinePlus } from 'react-icons/ai';
import Scene from '../../components/ScreenAnimation/ScreenAnimation';
import { FaPlay, FaPause } from "react-icons/fa";
import Confirm from '../../components/Confirm/Confirm';

function TitlePage() {
    // Используем JSON.parse для преобразования строки из localStorage в булево значение
    // Если в localStorage нет значения для 'playing', по умолчанию будет false
    const [isPlaying, setIsPlaying] = useState(JSON.parse(localStorage.getItem('playing')) || false);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        // Преобразуем булево значение в строку при сохранении в localStorage
        localStorage.setItem('playing', isPlaying);
    }, [isPlaying]);

    const handleStop = () => {
        localStorage.setItem('playing', false);
        setIsPlaying(false);
    }

    console.log(isPlaying);

    return (
        <section className={styles.container}>
            <div className={styles.scene__wrapper}>
                <div className={styles.scene}>
                    {isPlaying ? (
                        <FaPause className={styles.stop} onClick={() => handleStop()} />
                    ) : (
                        <FaPlay className={styles.play} onClick={() => setConfirm(true)} />
                    )}
                    {isPlaying && <Scene style={{ borderRadius: 20 }} />}
                </div>

                <ul className={styles.plus__menu}>
                    <li><AiOutlinePlus className={styles.plus} /></li>
                    <li><AiOutlinePlus className={styles.plus} /></li>
                    <li className={styles.scroll}>SCROLL TO EXPLORE</li>
                    <li><AiOutlinePlus className={styles.plus} /></li>
                    <li><AiOutlinePlus className={styles.plus} /></li>
                </ul>
            </div>

            {confirm && <Confirm setConfirm={setConfirm} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />}
        </section>
    );
}

export default TitlePage;
