import winAudio from '../../assets/audio/win.mp3';

function soundComplete() {
    const audio = new Audio(winAudio);
    audio.volume = 0.5;
    audio.play();
    audio.onended = () => {
        audio.src = '';
    };
}

export default soundComplete;
