import React from 'react';
import style from './PhoneDisplay.module.css';

function PhoneDisplay() {

  return (
    <section className={style.container}>
        <div className={style.text}>
          Прохождение заданий доступно только с ПК.
        </div>
    </section>
  );
}

export default PhoneDisplay;
