import React from 'react';
import style from './Lock.module.css';

const Lock = ({ isUnlocked }) => {

    return (
        <div className={`${style.padlock} ${isUnlocked ? style.unlock : ''}`}>
            <div className={style.keyhole}></div>
        </div>
    );
};

export default Lock;
