import React, { useState } from 'react';
import styles from './PhotoSection.module.css';
import { motion } from 'framer-motion';
import Lock from '../Lock/Lock';
import img from '../../assets/img/wizard.png';
import code from '../../assets/img/code.svg';
import { setRound } from '../../reducers/userReducer';
import { checkAnswer } from '../../http/userApi';
import { useDispatch, useSelector } from 'react-redux';
import soundComplete from '../SoundComplete/soundComplete';

function PhotoSection() {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const textAnimation = {
        hidden: {
            y: 50,
            opacity: 0
        },
        visible: custom => ({
            y: 0,
            opacity: 1,
            transition: { delay: custom * 0.2 }
        })
    }

    const [codeValue, setCodeValue] = useState('');

    const checkCode = async () => {
        if (!user.rounds[1]) {
            const data = await checkAnswer(codeValue, 2);
            dispatch(setRound({ rounds: data.rounds }));

            data.status && soundComplete();
        }
    };

    return (
        <motion.div className={styles.bio__section} whileInView={'visible'} initial="hidden" viewport={{ amount: 0.2, once: true }} >

            <img className={styles.img__1} src={code} alt="" />
            <img className={styles.img__2} src={code} alt="" />

            <div className={styles.info__wrapper}>
                <motion.div className={styles.info} custom={1} variants={textAnimation}>
                    <motion.a className={styles.text} href={img} download='the_koldun.png'>У всех на виду</motion.a>
                </motion.div>

                <div className={styles.lock__wrapper}>
                    <div className={styles.text__area__wrapper}>
                        <h3 className={styles.h3} >Поле для ввода ваших идей</h3>
                        <textarea value={user.rounds[1] ? 'Успешно завершено!' : codeValue} onChange={e => setCodeValue(e.target.value)}
                            className={`${styles.text__area} ${user.rounds[1] ? styles.text__area__unlocked : ''}`}
                            type='code' placeholder='Введите ваш ответ здесь...' readOnly={user.rounds[1]} />
                        <button className={!user.rounds[1] ? styles.button : styles.button__unlocked} onClick={() => checkCode()}>Отправить на проверку</button>
                    </div>

                    <Lock isUnlocked={user.rounds[1]} />
                </div>
            </div>

        </motion.div>
    );
}

export default PhotoSection;
